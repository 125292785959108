import {
  useContext,
  type JSX,
} from "solid-js";
import { Navigate, Route } from "@solidjs/router";
import { UserContext } from "./components/UserContext";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ForgotPasswordSubmitPage from "./pages/ForgotPasswordSubmit";
import AnnotateDatasetPage from "./pages/AnnotateDatasetPage";
import AnnotationPage from "./pages/Annotation";
import DashboardPage from "./pages/Dashboard";
import InspectionPage from "./pages/Inspection";
import StatisticsPage from "./pages/Statistics";
import { LandingPage } from "./pages/Landing";
import ServicePage from "./pages/Service";
import SettingsPage from "./pages/Settings";
import SignInPage from "./pages/SignIn";
import SignOutPage from "./pages/SignOut";
import SignUpPage from "./pages/SignUp";
import StartPage from "./pages/Start";
import UserProfilePage from "./pages/UserProfile";
import DatasetGallery from "./pages/Annotation/components/DatasetGallery";

interface AppProps {
  children?: JSX.Element;
}

function AdminRoute({
  children,
  requiredRole,
}: {
  children: JSX.Element;
  requiredRole?: string;
}) {
  const user = useContext(UserContext);

  if (!user?.accessToken || (requiredRole && user.group !== requiredRole)) {
    return <Navigate href="/dashboard" />;
  }

  return children;
}

const App: (props: AppProps) => JSX.Element = (props: AppProps) => {
  return (
    <>
      <Route path="/" component={StartPage} />
      <Route path="/annotation" component={AnnotationPage} />
      <Route path="/annotation/dataset/:id" component={DatasetGallery} />
      <Route path="/annotation/:id" component={AnnotateDatasetPage} />
      
      <Route path="/dashboard" component={DashboardPage} />
      <Route path="/inspection">
        <Route path=":id" component={InspectionPage} />
      </Route>
      <Route path="/landing" component={LandingPage} />
      <Route path="/settings" component={SettingsPage} />
      <Route path="/sign-in" component={SignInPage}>
        {import.meta.env.VITE_ENABLE_FORGOT_PASSWORD === "true" && (
          <>
            <Route path="forgot-password" component={ForgotPasswordPage} />
            <Route
              path="forgot-password-submit"
              component={ForgotPasswordSubmitPage}
            />
          </>
        )}
      </Route>
      <Route path="/sign-out" component={SignOutPage} />
      {import.meta.env.VITE_ENABLE_REGISTRATION === "true" && (
        <Route path="/sign-up" component={SignUpPage} />
      )}
      <Route
        path="/service"
        component={() => (
          <AdminRoute requiredRole="admin">
            <ServicePage />
          </AdminRoute>
        )}
      />
      <Route path="/statistics" component={StatisticsPage} />
      <Route path="/userprofile" component={UserProfilePage} />
      <Route path="*" component={() => <Navigate href="/" />} />
    </>
  );
};

export default App;
