//Contains typedefinitions for objects retrieved from DB

export enum AnnotationOriginStatus {
  HumanGenerated = "humanGenerated",
  AiGenerated = "aiGenerated",
  AiGeneratedHumanApproved = "aiGeneratedHumanApproved",
  AiGeneratedHumanRejected = "aiGeneratedHumanRejected",
}

export enum AnnotationValuation {
  Good = "Good",
  Bad = "Bad",
  Neutral = "Neutral",
}

export enum AnnotationTypes {
  Box = "BoxAnnotation",
  SingleLabel = "SingleLabelClassificationAnnotation",
  Measurement = "MeasurementAnnotation",
}

export enum ClassLabelColors {
  Green = "Green",
  Red = "Red",
  Blue = "Blue"
}

export type Box = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type UpdateImageAnnotationInput = {
  ImageAnnotation_belongsToImageContainerID: string;
  annotationData: {
    annotationType: AnnotationTypes;
    box: Box;
    singleLabelClassification: string;
  };
};

export type DatasetMinimal = {
  annotationType: AnnotationTypes;
  classes: {
    items: {
      color: string;
      id: string;
      name: string;
    }[];
  };
  id: string;
  name: string;
};

export type Annotation = {
  annotationData: {
    annotationType: AnnotationTypes;
    box: Box;
    measurement: {
      extraOutputData: string;
      value: number;
    };
    singleLabelClassification: {
      label: {
        color: string;
        id: string;
        name: string;
      };
      confidence: number;
    };
  };
  createdAt: string;
  id: string;
  needsReview: boolean;
  originStatus: AnnotationOriginStatus;
  updatedAt: string;
};

export type ImageContainer = {
  annotationComplete: boolean | undefined;
  annotations: {
    items: Annotation[];
  };
  createdAt: string;
  dataset: DatasetMinimal;
  id: string;
  imageKey: string;
  name: string;
  needsReview: boolean;
  source: {
    id: string;
    name: string;
  };
  updatedAt: string;
  smallURL: string;
  largeURL: string;
  originalURL: string;
};

export type ImageContainerMinimum = {
  id: string;
};

export type User = {
  accessToken: string;
  email: string | undefined;
  userName: string;
  refetchUserData: any;
};

export enum AuthSignInErrorCode {
  USER_NOT_CONFIRMED = "UserNotConfirmedException",
  USER_NOT_FOUND = "UserNotFoundException",
  USER_NOT_AUTHORIZED = "NotAuthorizedException",
  USER_REQUIRES_PASSWORD_RESET = "PasswordResetRequiredException",
}

export enum AuthForgotPasswordErrorCode {
  USER_NOT_FOUND = "UserNotFoundException",
}

export enum AuthForgotPasswordSubmitErrorCode {
  USER_NOT_FOUND = "UserNotFoundException",
  EXPIRED_CODE = "ExpiredCodeException",
  MISMATCHED_CODE = "CodeMismatchException",
}

export type GetDatasetType = {
  getDataset: {
    annotationType: AnnotationTypes;
    classes: {
      items: {
        color: string;
        id: string;
        name: string;
      }[];
    };
    id: string;
    images: {
      items: ImageContainer[];
    };
    name: string;
  };
};

export type TasksList = {
  listTask: {
    items: {
      createdAt: Date;
      description: {
        description: string;
      };
      id: string;
      imageKey: string | null;
      machine: {
        id: string;
        name: string;
        status: number;
      };
      MachineID: string | null;
      ModelID: string | null;
      updatedAt: Date;
      url: string | null;
    }[];
  };
};

// For servicepage
export type DatasetList = {
  listDataset: {
    items: {
      name: string;
      id: string;
      annotationType: AnnotationTypes;
      classes: {
        items: {
          color: string;
          name: string;
          id: string;
          modelClassId: string;
        }[];
      };
      models: {
        items: {
          Model: {
            name: string;
            id: string;
          };
        }[];
      };
      createdAt: string;
      updatedAt: Date;
      images: {
        items: {
          id: string;
        }[];
      };
    }[];
  };
};

export type SingleDatasetOverview = {
  name: string;
  id: string;
  annotationType: AnnotationTypes;
  classes: {
    items: {
      color: string;
      name: string;
      id: string;
      modelClassId: string;
    }[];
  };
  models: {
    items: {
      Model: {
        name: string;
        id: string;
      };
    }[];
  };
  createdAt: string;
  updatedAt: Date;
  images: {
    items: {
      id: string;
    }[];
  };
};

export type MachineList = {
  listMachine: {
    items: {
      config: string;
      createdAt: string;
      id: string;
      name: string;
      updatedAt: Date;
    }[];
  };
};

export type ImageCont = {
  createImageContainer: {
    id: string;
  };
};
