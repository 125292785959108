import {
  Accessor,
  createEffect,
  createSignal,
  For,
  Match,
  Show,
  Switch,
  useContext,
} from "solid-js";
import { AnnotationTypes } from "../../helpers/types";
import { useI18n } from "@solid-primitives/i18n";
import { createGraphQLClient } from "../../helpers/graphql";
import { UserContext } from "../../components/UserContext";
import {
  _GetDatasetImagesAndAnnotationsQuery,
  _ListDatasetsQueryMinimal,
} from "../../helpers/queries";
import { DatasetImagesAndAnnotations } from "../../helpers/types";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { AbortError } from "../../helpers/errors";
import { SpinnerIcon, ErrorIcon } from "../../components/Icons";
import { DatasetRow } from "./components/DatasetRow";



export default function AnnotationPage() {
  const [t] = useI18n();
  const user = useContext(UserContext);
  const client = createGraphQLClient(user?.accessToken);

  const [datasetsList, { refetch: refetchDatasets }] = client<{
    listDataset: {
      items: {
        annotationType: AnnotationTypes;
        id: string;
        name: string;
      }[];
    };
  }>(_ListDatasetsQueryMinimal);

  const datasetListSortedByName = () => {
    return datasetsList()?.listDataset.items.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const [downloadErrorMessage, setDownloadErrorMessage] =
    createSignal<boolean>(false);  
  const [downloadedImages, setDownloadedImages] = createSignal<number>(0);
  const [downloadingId, setDownloadingId] = createSignal<string>("");
  let abortDownload = false;

  const [downloadQueryInput, setDownloadQueryInput] = createSignal<
    Object | boolean
  >(false);

  const [datasetToDownload] = client<DatasetImagesAndAnnotations>(
    _GetDatasetImagesAndAnnotationsQuery,
    downloadQueryInput,
  );

  createEffect(async () => {
    if (
      !datasetToDownload.loading &&
      !datasetToDownload.error &&
      datasetToDownload()
    ) {
      setDownloadErrorMessage(false);

      try {
        setDownloadingId(datasetToDownload()?.getDataset.id || "");
        setDownloadedImages(0);
        const images = datasetToDownload()?.getDataset.images.items;
        console.log(images);

        if (images !== undefined) {
          const annotations = images.map((image) => ({
            image_id: image.id,
            imageKey: image.imageKey,
            url: image.url,
            source: {
              id: image.source.id,
              name: image.source.name,
            },
            annotations: image.annotations.items.map((annotation) => ({
              label:
                annotation.annotationData.singleLabelClassification.label.name,
              originStatus: annotation.originStatus,
            })),
          }));

          const zip = new JSZip();

          zip.file("annotations.json", JSON.stringify(annotations, null, 2));

          const imageFolder = zip.folder("images");

          if (imageFolder !== null) {
            for (const image of images) {
              if (abortDownload) {
                throw new AbortError();
              }
                   
              var originalURL;

              if(image.url.endsWith("/large")){
                originalURL = image.url.slice(0, -"large".length) + "original";
              }
              else{
                originalURL = image.url + "/original"; 
              }

              const response = await fetch(originalURL);
              if (!response.ok) {
                console.error("Failed to fetch signed URL for image:", image.id);
                continue;
              }

              const { url: signedUrl } = await response.json();

              const imageResponse = await fetch(signedUrl, {cache: "no-store"})
              const blob = await imageResponse.blob();
              imageFolder.file(`${image.id}.png`, blob);
              setDownloadedImages(downloadedImages() + 1);
            }
            const zipBlob = await zip.generateAsync({ type: "blob" });

            saveAs(
              zipBlob,
              `${datasetToDownload()?.getDataset.id}_dataset.zip`,
            );
          }
        }
        setDownloadErrorMessage(false);
        setDownloadingId("");
        return;
      } catch (error) {
        if (error instanceof AbortError) {
          console.log("Abort");
          setDownloadErrorMessage(false);
        } else {
          console.log("Error downloading dataset: " + datasetToDownload.error);
          setDownloadErrorMessage(true);
        }
      }
      finally {
        setDownloadingId("");
        abortDownload = false;
        setDownloadQueryInput(false);
      }
    }
  });
 
  const abortDownloadHander = () => {
    abortDownload = true;
  }

  const downloadDatasetHandler = (id: string) => {
    const input = { getDatasetId: id };
    setDownloadQueryInput(input);
  };

  return (
    <div class="min-h-screen">
      <div class="py-10">
        <header>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              {t("datasetsToAnnotate", {}, "Datasets to annotate")}
            </h1>
          </div>
        </header>

        <main>
          <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="mt-8 flex flex-col">
              <div class="inline-block min-w-full py-2 align-middle">
                <div class="shadow-sm ring-1 ring-black ring-opacity-5">
                  <table
                    class="min-w-full border-separate"
                    style={{
                      "border-spacing": "0",
                    }}
                  >
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {t("name", {}, "Name")}
                        </th>
                        <th
                          scope="col"
                          class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                        >
                          {t("type", {}, "Type")}
                        </th>
                        <th
                          scope="col"
                          class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                        />
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <Switch
                        fallback={
                          <For each={datasetListSortedByName()}>
                            {(dataset) => (
                              <DatasetRow
                                annotationType={dataset.annotationType}
                                id={dataset.id}
                                name={dataset.name}
                                downloadDataset={downloadDatasetHandler}
                                downloading={downloadingId}
                                abort={abortDownloadHander}
                              />
                            )}
                          </For>
                        }
                      >
                        <Match when={datasetsList.error}>
                          <tr>
                            <td colspan="6">
                              <div class="mx-auto my-2 py-1 rounded-full text-center">
                                <ErrorIcon />
                                Data could not be loaded |{" "}
                                <button
                                  onClick={() => refetchDatasets()}
                                  class="text-indigo-600 hover:text-indigo-900"
                                >
                                  {" "}
                                  RETRY{" "}
                                </button>
                              </div>
                            </td>
                          </tr>
                        </Match>
                        <Match when={datasetsList.loading}>
                          <tr>
                            <td colspan="6">
                              <div class="bg-nl-violet-900 mx-auto my-2 py-1 rounded-full text-center text-white w-48">
                                <SpinnerIcon />
                                {t("loading", {}, "Loading")}...
                              </div>
                            </td>
                          </tr>
                        </Match>
                      </Switch>
                    </tbody>
                  </table>
                </div>
                <Show when={downloadingId() !== ""}>
                  <p class="block text-md font-medium text-yellow-700 m-1 text-right">
                    {downloadedImages()}/
                    {datasetToDownload()?.getDataset.images.items.length}
                  </p>                
                </Show>
                <Show when={downloadErrorMessage()}>
                  <p class="block text-sm font-medium text-red-700 m-2">
                    *Something went wrong, could not download dataset. See
                    console for more information.
                  </p>
                </Show>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
